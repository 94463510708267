import {CaseMessageDto, CaseMessageRecipientDto, CaseMessageRecipients} from "../../api";
import styles from "./caseMessage.module.less";
import {Row, Tag} from "antd";
import ProfilePicture from "../profilePicture/profilePicture";
import moment from "moment";
import {MessageSortDirection} from "./MessageSortDirection";

export default function CaseMessage(props: CaseMessageProps) {
    const getMessageRecipient = (recipient: CaseMessageRecipientDto, index: number) => {
        switch (recipient.type) {
            case CaseMessageRecipients.Client:
                return <Tag key={index}>
                    Klient
                </Tag>
            case CaseMessageRecipients.Expert:
                return <Tag key={index}>
                    Ekspert
                </Tag>
            case "Patron" as any:
                return <Tag key={index}>
                    Opiekun klienta
                </Tag>
            case CaseMessageRecipients.Manager:
                return <Tag key={index}>
                    {recipient.recipient?.firstName} {recipient.recipient?.lastName} {recipient.recipient?.managerTitle?.name || "Manager"}
                </Tag>
        }
    }

    const getSortExpression = (a: string, b: string) => {
        switch (props.sortDirection) {
            case MessageSortDirection.Newest:
                return new Date(b) < new Date(a) ? 1 : -1;
            case MessageSortDirection.Oldest:
                return new Date(b) > new Date(a) ? 1 : -1;
        }
    }

    return <>
        {props.messages !== null ?
            props.messages!
                .sort((a, b) => getSortExpression(a.createdAt!, b.createdAt!))
                .map((message, index) => <Row key={index} className={styles.message}>
                    <div className={styles.profilePicture}>
                        <ProfilePicture
                            profilePictureId={message.createdBy?.profileImageId}
                            size={30}
                        />
                    </div>
                    <div className={styles.messageDetails}>
                        <Row>
                        <span className={styles.author}>
                            {!message.createdBy ? "—" : `${message.createdBy.firstName} ${message.createdBy.lastName}`}
                        </span>
                            <span className={styles.createdAt}>
                            {moment(message.createdAt).format("YYYY-MM-DD HH:mm")}
                        </span>
                            {message.recipients ? message.recipients!.map((recipient, index) =>
                                getMessageRecipient(recipient, index)) : null}
                        </Row>
                        <Row>
                        <span className={styles.content}>
                            {message.content}
                        </span>
                        </Row>
                    </div>
                </Row>)
            : null}
    </>
}

interface CaseMessageProps {
    messages: CaseMessageDto[] | null,
    sortDirection: MessageSortDirection
}
