import {Tag} from "antd";
import {Link} from "react-router-dom";
import styles from "./userTile.module.less"
import React from "react";
import {ApplicationUserDto, ApplicationUserStatus} from "../../api";
import ProfilePicture from "../profilePicture/profilePicture";
import UserTileOptions from "./userTileOptions";
import { getOrganisationId } from "../../hooks/useCurrentOrganisation";

export default function UserTile(props: UserTileComponentProps) {
    return <>
        <div className={`${styles.tileContainer} ${(props.user.hasSubscription && getOrganisationId() === 0) && styles.hasSubscription} ${props.user.isDeleted && styles.isDeleted}`}>
                <Link to={props.getProfilePath(props.user.id!)}
                        className={styles.userInfoWrapper}>
                    <div className={styles.profileWrapper}>
                        <ProfilePicture className={styles.userProfilePicture}
                                        profilePictureId={props.user.profileImageId}/>
                        <UserTags user={props.user}/>
                    </div>
                    <div className={styles.userInfo}>
                        <div className={styles.userName}>{props.user.firstName} {props.user.lastName}</div>
                        <div className={styles.userInfoItem}>{props.user.phoneNumber}</div>
                        <div className={styles.userInfoItem}>{props.user.email}</div>
                        <div className={styles.userInfoItem}>{props.user.profession?.name}</div>
                    </div>
                </Link>

            {!props.hideActions && <>
                <div className={styles.horizontalSeparator}/>
                <div className={styles.icons}>
                    <UserTileOptions {...props} />
                </div>
            </>}
        </div>
    </>;
}

export function userStatusToString(status: ApplicationUserStatus) {
    switch (status) {
        case ApplicationUserStatus.Deleted:
            return "Deaktywowany";
        case ApplicationUserStatus.NotConfirmed:
            return "Nieaktywny";
        case ApplicationUserStatus.Registered:
            return "Zgłoszony";
        case ApplicationUserStatus.RegisteredNotConfirmed:
            return "Zgłoszony/Nieaktywny";
        case ApplicationUserStatus.Active:
            return "Aktywny";
        default:
            return null;
    }
}

export function UserTags({user}: { user: ApplicationUserDto }) {

    switch (user.status) {
        case ApplicationUserStatus.Deleted:
            return <Tag color="error" className={styles.tag}>Deaktywowany</Tag>;
        case ApplicationUserStatus.NotConfirmed:
            return <Tag className={styles.tag}>Nieaktywny</Tag>;
        case ApplicationUserStatus.Registered:
            return <Tag color="success" className={styles.tag}>Zgłoszony</Tag>;
        case ApplicationUserStatus.RegisteredNotConfirmed:
            return <>
                <Tag color="success" className={styles.tag}>Zgłoszony</Tag>
                <Tag className={styles.tag}>Nieaktywny</Tag>
            </>;
        default:
            return null;
    }
}

export interface UserTileComponentProps {
    getProfilePath(id: number): string;

    user: ApplicationUserDto,
    onResendClick: (id: number) => Promise<void>,
    onDelete: (id: number) => void,
    casesPath: string,
    hideActions?: boolean
}
