import {Button, Col, Input, message, Row, Select, Spin, Tabs} from "antd";
import Title from "antd/lib/typography/Title";
import styles from "./caseDetails.module.less";
import {useCallback, useEffect, useState} from "react";
import ProfilePicture from "../../../components/profilePicture/profilePicture";
import {useInjection} from "inversify-react";
import ApiService from "../../../services/apiService";
import {
    CaseDetailsDto,
    CaseMessageApi,
    CaseMessageCreateDto,
    CaseMessageDto,
    CaseMessageRecipientDto,
    CaseMessageRecipients,
    NotificationsApi
} from "../../../api";
import {useParams} from "react-router-dom";
import CaseMessage from "../../../components/caseMessage/caseMessage";
import {MessageSortDirection} from "../../../components/caseMessage/MessageSortDirection";
import CaseDetailsTimeline from "./caseActivity/caseDetailsTimeline";
import { DefaultOptionType } from "antd/lib/select";
import { useDispatch, useSelector } from "react-redux";
import { getCurrentUser } from "../../../redux/applicationUserSlice";
import classNames from "classnames";
import { setNotificationsData } from "../../../redux/notificationSlice";
import {loadingSpin} from "../../routing/routingContainer";
import { useInView } from "react-intersection-observer";

const {TabPane} = Tabs;
const {TextArea} = Input;

export default function CaseDetailsActivityTile(props: CaseDetailsActivityTileProps) {
    const apiService = useInjection(ApiService);
    const {id} = useParams();

    const [caseMessage, setCaseMessage] = useState<CaseMessageDto[] | null>();
    const [sortTerm, setSortTerm] = useState<MessageSortDirection>(MessageSortDirection.Oldest);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const dispatch = useDispatch();
    const {inView, ref} = useInView();

    useEffect(() => {
        if (inView) {
            refreshComments();
        }
    }, [inView, id]);

    const updateNotifications = useCallback(async () => {
        const newsNotifications = await apiService.getApi(NotificationsApi).apiNotificationsGet();
        dispatch(setNotificationsData(newsNotifications.data));
    }, [apiService, dispatch]);

    const refreshComments = async () => {
        try {
            setIsLoading(true);
            const response = await apiService.getApi(CaseMessageApi).apiCaseMessageCaseCaseIdGet(+id!);
            setCaseMessage(response.data);
            updateNotifications();
        } catch (e: any) {
            console.log(e.response.data);
        }

        setIsLoading(false);
    }

    const changeSortTerm = () => {
        setSortTerm(sortTerm == MessageSortDirection.Oldest
            ? MessageSortDirection.Newest
            : MessageSortDirection.Oldest);
    }

    return <Col ref={ref} className={styles.activityContainer}>
        <Title level={3} className={styles.title}>
            Aktywność
        </Title>
            <Button onClick={changeSortTerm} className={classNames(styles.sortButton, styles.showOnSm)}>
                <b>Sortuj: </b>{sortTerm == MessageSortDirection.Oldest ? "Od najstarszych" : "Od najnowszych"}
            </Button>
        <Tabs style={{width: "100%"}} tabBarExtraContent={
            <Button onClick={changeSortTerm} className={classNames(styles.sortButton, styles.hideOnSm)}>
                <b>Sortuj: </b>{sortTerm == MessageSortDirection.Oldest ? "Od najstarszych" : "Od najnowszych"}
            </Button>
        }>
            <TabPane tab="Komentarze" key="1">
                <Spin indicator={loadingSpin} spinning={isLoading} delay={200}>
                    <AddNewCommentSection currentCase={props.currentCase} onCommentAdded={() => refreshComments()} />
                    <CaseMessage
                        messages={caseMessage || null}
                        sortDirection={sortTerm}
                    />
                </Spin>
            </TabPane>
            <TabPane tab="Przebieg sprawy" key="2">
                <CaseDetailsTimeline messages={caseMessage || undefined} currentCase={props.currentCase} sortDirection={sortTerm} />
            </TabPane>
        </Tabs>
    </Col>
}

interface CaseDetailsActivityTileProps {
    currentCase: CaseDetailsDto
}

export function AddNewCommentSection(props: { currentCase: CaseDetailsDto, onCommentAdded: () => void, sendToAll?: boolean }) {
    const currentUser = useSelector(getCurrentUser);
    const [isAddNewCommentButtonDisabled, setIsAddNewCommentButtonDisabled] = useState<boolean>(true);
    const [commentRecipients, setCommentRecipients] = useState<CaseMessageRecipientDto[]>([]);
    const [newComment, setNewComment] = useState<string>("");
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const apiService = useInjection(ApiService);

    const selectOptions: DefaultOptionType[] = [];
    const client = props.currentCase.client;
    const clientName = client?.firstName + " " + client?.lastName;

    if (currentUser?.id !== props.currentCase.clientId) {
        selectOptions.push({
            value: JSON.stringify({ type: CaseMessageRecipients.Client }),
            label: `${clientName} (klient)`
        });
    }

    if (props.currentCase.expertId && currentUser?.id !== props.currentCase.expertId) {
        selectOptions.push({
            value: JSON.stringify({ type: CaseMessageRecipients.Expert }),
            label: `${props.currentCase.expert?.firstName} ${props.currentCase.expert?.lastName} (ekspert)`
        });
    }

    selectOptions.push(...props.currentCase.managers?.filter(m => m.id !== currentUser?.id).map(m => ({
        value: JSON.stringify({ type: CaseMessageRecipients.Manager, recipientId: m.id }),
        label: `${m.firstName} ${m.lastName} (${m.managerTitle?.name || "manager"})`
     })) || []);

     const updateNewComment = (e: any) => {
         setNewComment(e.target.value);
         setIsAddNewCommentButtonDisabled(false);
     }
 
     const onCommentAddClick = async () => {
         try {
             if (newComment) {
                 const caseMessageToCreate: CaseMessageCreateDto = {
                     caseId: +props.currentCase.id!,
                     content: newComment,
                     recipients: commentRecipients
                 };
 
                 setIsLoading(true);
 
                 try {
                    await apiService.getApi(CaseMessageApi).apiCaseMessagePost(caseMessageToCreate);
                    setIsAddNewCommentButtonDisabled(true);
                    setNewComment("");
                    setCommentRecipients([])
    
                    props.onCommentAdded();
                 } finally {
                    setIsLoading(false);
                 }
             } else {
                 message.info("Komentarz do sprawy nie może być pusty");
             }
         } catch (e: any) {
             console.log(e.response.data);
         }
     }

     useEffect(() => {
        if (props.sendToAll) {
            setCommentRecipients(selectOptions.map(o => JSON.parse(o.value! as any)).filter(r => r.type !== CaseMessageRecipients.Client && r.type !== CaseMessageRecipients.Expert));
        }
     }, [props.sendToAll]);

    return <Row>
        <div className={styles.messageFormWrapper}>
            <div>
                <ProfilePicture
                    profilePictureId={currentUser?.profileImageId}
                    size={30} />
            </div>
            <div className={styles.messageForm}>
                <div className={styles.to}>
                    <span className={styles.label}>Do</span>
                    <Select mode="multiple" style={{ width: "100%" }} onChange={v => setCommentRecipients(v.map(r => JSON.parse(r)))}
                        value={commentRecipients.map(r => JSON.stringify(r))} options={selectOptions} disabled={props.sendToAll} />
                </div>
                <TextArea
                    placeholder="Wpisz treść komentarza"
                    onChange={updateNewComment}
                    autoSize={{ minRows: 2 }}
                    value={newComment} />
                <Button type="primary" onClick={onCommentAddClick} className={styles.addCommentButton}
                    loading={isLoading}
                    disabled={isLoading || isAddNewCommentButtonDisabled || commentRecipients!.length < 1}>
                    Dodaj komentarz
                </Button>
            </div>
        </div>
    </Row>;
}

